import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import SEO from "../components/seo";
import PageLayout from '../components/page-layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title={"Software"} mdxType="SEO" />
    <h1>{`Software`}</h1>
    <p>{`This is a `}<a parentName="p" {...{
        "href": "https://www.ros.org/"
      }}>{`ROS (Robot Operating System)`}</a>{` based robot, so the custom software here is mainly used to bridge the hardware to conform to ROS specifications.`}<br parentName="p"></br>{`
`}{`AvoBot runs Ubuntu 20.04 with ROS 2 (Foxy Fitzroy) installed on it.`}<br parentName="p"></br>{`
`}{`It accepts velocity commands from ROS and passes them to Arduino via a serial port.`}<br parentName="p"></br>{`
`}{`Arduino then controls the motors. Arduino has a PID control which attempts to compensate for motors imperfections (further running needed).`}<br parentName="p"></br>{`
`}{`RPi also runs an RPLidar node which sends scan results to ROS.`}<br parentName="p"></br>{`
`}{`The rest of the software is run on another computer - SLAM, Navigation and RViz.`}<br parentName="p"></br>{`
`}{`In theory, after they are tuned these packages can also run on RPi, but for experiments, it's more convenient to run hardware-related nodes on RPi and the rest on another computer.`}<br parentName="p"></br>{`
`}{`Due to the distributed nature of ROS 2 design, it doesn't really matter where non-hardware nodes run.`}<br parentName="p"></br>{`
`}{`I'm using Balena to deploy updates to RPi. All it takes is a git push to deploy.`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://github.com/Leonti/ros-rover"
      }}>{`Here`}</a>{` is the repository with all of the code needed for AvoBot to run.`}<br parentName="p"></br>{`
`}{`It's not fully cleaned up yet but should run after small tweaks to folder paths.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      